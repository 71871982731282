import React, { useState, useCallback } from "react";
import {
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  Menu as MenuIcon,
  AccessTime,
  Star,
  Folder,
  Delete as DeleteIcon,
  DeleteOutlineOutlined,
  DeleteTwoTone,
  AccessTimeTwoTone,
  TryTwoTone,
  SourceTwoTone,
  ScheduleOutlined,
  TryOutlined,
  FolderOutlined,
} from "@mui/icons-material";
import { useData } from "../contexts/DataContext";

const drawerWidth = 240;
const collapsedWidth = 60;

function Sidebar({
  selectProject,
  selectBoard,
  selectedProject,
  selectedBoard,
  onDeleteProject,
  onDeleteBoard,
}) {
  const { getUserProjects, getUserBoards } = useData();
  const [openSection, setOpenSection] = useState("");
  const [openProjects, setOpenProjects] = useState({});
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const userProjects = getUserProjects();
  const userBoards = getUserBoards();

  const handleSectionClick = useCallback((section) => {
    setOpenSection((prevSection) => (prevSection === section ? "" : section));
  }, []);

  const handleProjectClick = useCallback(
    (projectId) => {
      setOpenProjects((prev) => ({ ...prev, [projectId]: !prev[projectId] }));
      selectProject(projectId);
    },
    [selectProject]
  );

  const toggleSidebar = useCallback(() => {
    setIsSidebarCollapsed((prev) => !prev);
  }, []);

  const handleDeleteClick = useCallback((item, type) => {
    setItemToDelete({ item, type });
    setDeleteConfirmOpen(true);
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    if (itemToDelete?.type === "project") {
      onDeleteProject(itemToDelete.item.id);
    } else if (itemToDelete?.type === "board") {
      onDeleteBoard(itemToDelete.item.id);
    }
    setDeleteConfirmOpen(false);
    setItemToDelete(null);
  }, [itemToDelete, onDeleteProject, onDeleteBoard]);

  const renderProjectList = useCallback(
    (projectList) => {
      if (!projectList || projectList.length === 0) {
        return (
          <Typography variant="body2" sx={{ pl: 4 }}>
            No projects available
          </Typography>
        );
      }

      return (
        <>
          {projectList.map((project) => (
            <React.Fragment key={project.id}>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handleProjectClick(project.id)}
                selected={selectedProject && selectedProject.id === project.id}
              >
                <ListItemText
                  primary={
                    typeof project.name === "string"
                      ? project.name
                      : "Unnamed Project"
                  }
                />
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteClick(project, "project");
                  }}
                >
                  <DeleteOutlineOutlined
                    style={{
                      width: "22px",
                      height: "22px",
                      color: "#c7e290"
                    }}
                  />
                </IconButton>
                {openProjects[project.id] ? (
                  <ExpandLess sx={{ ml: 2 }} />
                ) : (
                  <ExpandMore sx={{ ml: 2 }} />
                )}
              </ListItemButton>
              <Collapse
                in={openProjects[project.id]}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {userBoards
                    .filter((board) => board.projectId === project.id)
                    .map((board) => (
                      <ListItemButton
                        sx={{ pl: 4, pr: 6 }}
                        key={board.id}
                        selected={
                          selectedBoard && selectedBoard.id === board.id
                        }
                        onClick={() => selectBoard(board.id)}
                      >
                        <ListItemText
                          primary={
                            typeof board.name === "string"
                              ? board.name
                              : "Unnamed Board"
                          }
                        />
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          sx={{ p: 0 }}
                          style={{marginRight: "4px"}}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteClick(board, "board");
                          }}
                        >
                          <DeleteOutlineOutlined
                            style={{
                              width: "22px",
                              height: "22px",
                              color: "#c7e290",
                            }}
                          />
                        </IconButton>
                      </ListItemButton>
                    ))}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
        </>
      );
    },
    [
      userBoards,
      selectedProject,
      selectedBoard,
      handleProjectClick,
      selectBoard,
      handleDeleteClick,
      openProjects,
    ]
  );

  if (!userProjects) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Drawer
        sx={{
          width: isSidebarCollapsed ? collapsedWidth : drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: isSidebarCollapsed ? collapsedWidth : drawerWidth,
            boxSizing: "border-box",
            transition: "width 0.2s",
            overflowX: "hidden",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 1,
            minHeight: 64,
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          {!isSidebarCollapsed && (
            <Box
              sx={{ flexGrow: 1, ml: 1, display: "flex", alignItems: "center" }}
            >
              <img
                src="/logo2.png"
                alt="Teamlist"
                style={{ maxHeight: "24px", maxWidth: "100%" }}
              />
            </Box>
          )}
          <IconButton onClick={toggleSidebar}>
            <MenuIcon />
          </IconButton>
        </Box>
        {!isSidebarCollapsed && (
          <List>
            <ListItemButton
              onClick={() => handleSectionClick("recentlyAccessed")}
            >
              <ScheduleOutlined
                sx={{ mr: 2, color: "#c7e290", width: "22px", height: "22px" }}
              />
              <ListItemText primary="Recently Accessed" />
              {openSection === "recentlyAccessed" ? (
                <ExpandLess sx={{ ml: 2 }} />
              ) : (
                <ExpandMore sx={{ ml: 2 }} />
              )}
            </ListItemButton>
            <Collapse
              in={openSection === "recentlyAccessed"}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {renderProjectList(userProjects.slice(0, 2))}
              </List>
            </Collapse>

            <ListItemButton onClick={() => handleSectionClick("favorites")}>
              <TryOutlined
                sx={{ mr: 2, color: "#c7e290", width: "22px", height: "22px" }}
              />
              <ListItemText primary="Favorites" />
              {openSection === "favorites" ? (
                <ExpandLess sx={{ ml: 2 }} />
              ) : (
                <ExpandMore sx={{ ml: 2 }} />
              )}
            </ListItemButton>
            <Collapse
              in={openSection === "favorites"}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {renderProjectList(userProjects.slice(0, 1))}
              </List>
            </Collapse>

            <ListItemButton onClick={() => handleSectionClick("projects")}>
              <FolderOutlined
                sx={{ mr: 2, color: "#c7e290", width: "22px", height: "22px" }}
              />
              <ListItemText primary="Projects" />
              {openSection === "projects" ? (
                <ExpandLess sx={{ ml: 2 }} />
              ) : (
                <ExpandMore sx={{ ml: 2 }} />
              )}
            </ListItemButton>
            <Collapse
              in={openSection === "projects"}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {renderProjectList(userProjects)}
              </List>
            </Collapse>
          </List>
        )}
      </Drawer>
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <Typography id="alert-dialog-description">
            Are you sure you want to delete this {itemToDelete?.type}? This
            action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Sidebar;
