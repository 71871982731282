// import React, { useState } from "react";
// import {
//   Box,
//   TextField,
//   Button,
//   Typography,
//   Container,
//   Paper,
// } from "@mui/material";
// import { styled } from "@mui/material/styles";
// import { useData } from "../contexts/DataContext";

// const StyledContainer = styled(Container)({
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   justifyContent: "center",
//   height: "100vh",
// });

// const StyledPaper = styled(Paper)(({ theme }) => ({
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   padding: theme.spacing(4),
//   width: "100%",
//   maxWidth: "400px",
// }));

// const Logo = styled("img")({
//   width: "200px",
//   marginBottom: "20px",
// });

// function Login({ onLogin }) {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [error, setError] = useState("");
//   const { members } = useData();

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     const user = members.find(
//       (member) => member.email === email && member.password === password
//     );
//     if (user) {
//       onLogin(user);
//     } else {
//       setError("Invalid email or password");
//     }
//   };

//   return (
//     <StyledContainer>
//       <StyledPaper elevation={6}>
//         <Logo src="/logo2.png" alt="Teamlist Logo" />
//         <Box
//           component="form"
//           onSubmit={handleSubmit}
//           noValidate
//           sx={{ mt: 1, width: "100%" }}
//         >
//           <TextField
//             margin="normal"
//             required
//             fullWidth
//             id="email"
//             label="Email Address"
//             name="email"
//             autoComplete="email"
//             autoFocus
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//           />
//           <TextField
//             margin="normal"
//             required
//             fullWidth
//             name="password"
//             label="Password"
//             type="password"
//             id="password"
//             autoComplete="current-password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//           />
//           {error && (
//             <Typography color="error" align="center" sx={{ mt: 2 }}>
//               {error}
//             </Typography>
//           )}
//           <Button
//             type="submit"
//             fullWidth
//             variant="contained"
//             sx={{ mt: 3, mb: 2 }}
//           >
//             Log In
//           </Button>
//         </Box>
//       </StyledPaper>
//     </StyledContainer>
//   );
// }

// export default Login;

import React, { useState } from "react";
import { Box, TextField, Button, Typography, Paper, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useData } from "../contexts/DataContext";

const StyledGrid = styled(Grid)(({ theme }) => ({
  height: "100vh",
}));

const ImageSection = styled(Box)({
  backgroundImage: "url('/loginBG.jpg')",
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "100%",
  height: "100vh",
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(4),
  width: "100%",
  maxWidth: "400px",
}));

const Logo = styled("img")({
  width: "150px",
  marginBottom: "20px",
});

function Login({ onLogin }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { members } = useData();

  const handleSubmit = (event) => {
    event.preventDefault();
    const user = members.find(
      (member) => member.email === email && member.password === password
    );
    if (user) {
      onLogin(user);
    } else {
      setError("Invalid email or password");
    }
  };

  return (
    <Grid container component="main" className={StyledGrid}>
      {/* Left Side - Image */}
      <Grid item xs={false} sm={4} md={7}>
        <ImageSection />
      </Grid>

      {/* Right Side - Form */}
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <StyledPaper elevation={6}>
          <Logo src="/logo2.png" alt="Teamlist Logo" />
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ width: "100%" }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Enter Username"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Enter Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {error && (
              <Typography color="error" align="center" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Log In
            </Button>
          </Box>
        </StyledPaper>
      </Grid>
    </Grid>
  );
}

export default Login;
